import { type CreateControllerFn } from '@wix/yoshi-flow-editor';
import { initFormController } from '@wix/form-viewer/controller';
import { getAppSettings } from '../../services/app-settings';
import { getFormId, getFormIdFromUrl } from '../../services/get-form-id';

const createController: CreateControllerFn = async ({
  flowAPI,
  controllerConfig,
}) => {
  const { setProps } = controllerConfig;
  return {
    async pageReady() {
      const appSettings = await getAppSettings(flowAPI.httpClient);
      const formId =
        getFormIdFromUrl(flowAPI) || (await getFormId(flowAPI.httpClient));

      try {
        await initFormController(flowAPI, {
          formId,
          namespace: 'wix.form_example.form',
        });
      } catch {}

      setProps({
        formId,
        appSettings,
      });
    },
  };
};

export default createController;
